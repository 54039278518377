/* Existing styles remain unchanged */

.donate-button {
  color: #28a745 !important;
  border: 1px solid #28a745;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
}

.donate-button:hover {
  background-color: #28a745 !important;
  color: white !important;
}

.donate-toast {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #dfffd6;
  padding: 10px;
  border: 1px solid #28a745;
  border-radius: 5px;
  font-size: 0.9rem;
  z-index: 1050;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}