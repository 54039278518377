.article-item {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

/* Headline style: black text, no underline or hover effect */
.article-headline {
  color: #000;
  font-weight: bold;
  text-decoration: none;
  font-size: 1.1em;
  margin-right: 10px;
}

.article-headline:hover {
  text-decoration: none;
  color: #000;
}

/* Domain name link: blue hover effect */
.article-domain {
  color: #0056b3;
  text-decoration: none;
}

.article-domain:hover {
  color: #003366;
  text-decoration: underline;
}

/* Container for Related Posts */
.related-posts-container {
  border-top: 1px solid #ddd; /* Subtle line underneath the entire section */
  padding-top: 0.5rem;
  margin-top: 1rem;
}

/* Header for Related Posts (e.g., Twitter label) */
.related-posts-header {
  font-size: 0.75rem; /* Smaller font size for "Twitter" */
  color: #666; /* Subtle gray */
  margin-bottom: 0.5rem;
}

/* Related Posts Links */
.related-posts {
  font-size: 0.85rem; /* General font size for related posts */
}

.related-post-link a {
  font-size: 0.65rem; /* Reduced font size for links */
  color: #888; /* Light gray text */
  text-decoration: none; /* No underline initially */
  padding: 2px 4px; /* Slight padding for hover effect */
}

.related-post-link a:hover {
  background-color: #e8f4fc; /* Subtle light blue highlight */
  text-decoration: underline; /* Underline on hover */
  color: #555; /* Slightly darker gray */
}

/* Ensure proper spacing between sections */
.related-posts ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.related-posts li {
  display: inline; /* Display items inline */
}

.related-posts-header {
  display: inline; /* Display "Twitter" inline with posts */
  font-weight: bold;
}