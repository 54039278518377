.transaction-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #e1e1e1; /* Adds a subtle bottom border */
  transition: background-color 0.2s ease;
}

.transaction-item:hover {
  background-color: #f9f9f9;
}

.logo-container {
  flex-shrink: 0; /* Prevents the logo container from shrinking */
  width: 50px; /* Fixed width to keep logo and text aligned */
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.transaction-text {
  font-size: 14px;
  color: #333;
  line-height: 1.2;
}