/* MainPage.css */

/* Ensure no horizontal scrolling */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
}

.main-page-container {
  padding: 20px;
  background-color: #f5f5f5;
}

.main-page {
  max-width: 100vw;
  overflow-x: hidden;
}

/* Section card styling */
.left-section,
.middle-section,
.right-section {
  background-color: #ffffff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 20px;
  background: linear-gradient(135deg, #f9f9f9, #f0f0f0);
}

/* Header styling */
.section-header {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 22px;
  font-weight: 700;
  color: #1d1d1f;
  margin-bottom: 15px;
  border-bottom: 2px solid #ececec;
  text-transform: uppercase;
}

/* Hide dropdown for desktop */
.pane-dropdown {
  display: none;
  margin-bottom: 20px;
}

/* Responsive styling */
@media (max-width: 768px) {
  /* Show dropdown for mobile */
  .pane-dropdown {
    display: block;
    width: 100%;
  }

  /* Hide all sections except the active one */
  .left-section,
  .middle-section,
  .right-section {
    display: none;
  }

  .left-section.active,
  .middle-section.active,
  .right-section.active {
    display: block;
  }
}