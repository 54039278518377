/* Container for the search bar */
.search-bar-container {
  position: relative;
  width: 100%;
  padding: 10px 0;
}

/* Suggestions list styling */
.suggestions-list {
  position: absolute;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for dropdown */
}

/* Individual suggestion item */
.suggestion-item {
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

/* Hover effect for suggestion items */
.suggestion-item:hover {
  background-color: #f5f5f5;
}

/* Scrollbar styling for suggestions list */
.suggestions-list::-webkit-scrollbar {
  width: 6px;
}

.suggestions-list::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 4px;
}