/* Ticker container */
.ticker-container {
  overflow: hidden;
  background-color: #fffbea;
  padding: 10px 0;
  width: 100%;
  margin-top: 10px;
}

/* Ticker content */
.ticker-content {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  animation: ticker-scroll 60s linear infinite;
}

/* Individual item styling */
.ticker-item {
  display: flex;
  align-items: center;
  margin-right: 50px;
  font-size: 1rem;
  color: #000;
}

/* Position number styling */
.item-position {
  font-weight: bold;
  margin-right: 10px; /* Add padding between number and item */
  color: #555;
}

/* Logo for teams */
.ticker-logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

/* Trending icon styling */
.trending-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

/* Header styling */
.ticker-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1rem;
  color: #000;
  margin-left: 170px;
  margin-right: 70px;
}

/* Change indicator */
.change-indicator {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #28a745;
  margin-left: 5px;
}

/* Continuous scrolling animation */
@keyframes ticker-scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}