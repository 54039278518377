.about-container {
  padding: 25px;
  max-width: 900px;
  margin: 0 auto;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.about-header {
  font-size: 2.4em;
  margin-top: 20px;
  color: #d50a0a;
  font-family: "Helvetica", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 3px solid #ddd;
  padding-bottom: 10px;
}

.about-page p {
  font-size: 1.15em;
  line-height: 1.7;
  color: #444;
  margin-bottom: 25px;
  font-family: "Helvetica", sans-serif;
}

/* Links */
a {
  color: #013369;
  font-weight: bold;
}

a:hover {
  color: #d50a0a;
}

.let-me-know {
  color: #013369;
  font-weight: bold;
}

.let-me-know:hover {
  color: #d50a0a;
}