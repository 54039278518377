/* Global app container and background */
.app {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Container for the main content */
.content {
  flex: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  max-width: 100%;
}

/* Link styles with Bootstrap coloring */
a {
  color: #0d6efd; /* Bootstrap primary color */
  text-decoration: none;
}

a:hover {
  color: #0a58ca; /* Darker shade for hover */
  text-decoration: underline;
}
