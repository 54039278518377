/* Footer.css */
.footer-container {
  background-color: #1a1a1a;
  color: #f0db4f;
  font-size: 0.9rem;
  width: 100%;
  padding: 10px 0;
  position: relative;
  font-family: "Helvetica Neue", Arial, sans-serif; /* Match header font */
  font-weight: bold; /* Match header weight if applicable */
  letter-spacing: 0.05em; /* Add subtle letter spacing to match header style */
}

.footer-container p {
  margin: 0;
  text-align: center;
}