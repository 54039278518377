.table td {
  vertical-align: middle;
}

.table td a {
  color: #007bff;
  text-decoration: none;
}

.table td a:hover {
  text-decoration: underline;
}