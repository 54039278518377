.video-item {
  width: 100%;
  height: 300px; /* Fixed consistent height for each card */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.video-thumbnail {
  width: 100%;
  height: 100px; /* Consistent height for the thumbnail */
  object-fit: cover;
  border-radius: 5px;
}

.video-title {
  font-size: 12px;
  font-weight: bold;
  color: #1a1a1a;
  margin: 5px 0;
  line-height: 1.2;
  height: 100px; /* Fixed height to limit overflow and ensure consistent card height */
  overflow: hidden;
  text-align: left;
}

.video-timestamp {
  font-size: 10px;
  color: #666;
  margin-top: auto; /* Push timestamp to the bottom */
  text-align: left;
}