.player-movements-container {
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.player-movements-list .list-group-item {
  padding: 10px 15px;
  transition: background-color 0.2s ease;
}