.container {
  max-width: 900px;
  margin: 0 auto;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.table {
  margin-top: 20px;
}

.alert {
  margin-top: 20px;
}

.text-center {
  text-align: center;
}