.donate-container {
  padding: 25px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.donate-header {
  font-size: 2em;
  margin-top: 20px;
  color: #28a745;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.donation-form {
  margin-top: 20px;
}

.amount-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.amount-button {
  padding: 10px 20px;
  border: 1px solid #28a745;
  background: white;
  color: #28a745;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.amount-button.selected,
.amount-button:hover {
  background: #28a745;
  color: white;
}

.custom-amount {
  margin-bottom: 20px;
}

.custom-amount input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.card-element {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.donate-submit {
  width: 100%;
  padding: 10px;
  background: #28a745;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.donate-submit:hover {
  background: #218838;
}

.donate-submit:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.donation-form .full-name {
  margin-bottom: 20px; /* Add padding below */
}

.donation-form .full-name input {
  width: 100%; /* Make it as long as other fields */
  text-align: left; /* Right-justify the text */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  margin-top: 5px;
}